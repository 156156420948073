import React, { useState } from 'react';
import Layout from "../../Layouts/Layout";

const styles = {
  helpContainer: {
    maxWidth: '800px',
    margin: '50px auto',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  },
  helpHeader: {
    textAlign: 'center',
    marginBottom: '30px'
  },
  helpHeaderH1: {
    fontSize: '2.5rem',
    margin: '0',
    color: '#4a4a4a'
  },
  helpItem: {
    borderBottom: '1px solid #ddd',
    padding: '15px 0'
  },
  helpQuestion: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  helpQuestionHover: {
    color: '#8c52ff'
  },
  helpQuestionActive: {
    transform: 'rotate(45deg)' // Rotate to make a plus sign a cross
  },
  helpAnswer: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-out',
	fontSize: '1rem',
    fontWeight: 'normal' // Removing bold effect
  },
  helpAnswerActive: {
    maxHeight: '200px' // Adjust as needed
  }
};

const questionsAndAnswers = [
  {
    question: "Comment faire un reset de mon mot de passe?",
    answer: "Prévoir la réponse ici pour dire comment faire."
  },
  {
    question: "Comment rajouter un fournisseur ou un produit?",
    answer: "Prévoir la réponse ici pour dire comment faire."
  },
  {
    question: "Est-il possible de modifier un fournisseur ou un produit?",
    answer: "Prévoir la réponse ici pour dire comment faire."
  },
  {
    question: "Quelle est la différence entre 'Montant Commande' et 'Montant Facture'?",
    answer: "Prévoir la réponse ici pour dire comment faire."
  },
  {
    question: "Comment mon fournisseur peut m'envoyer sa facture?",
    answer: "Prévoir la réponse ici pour dire comment faire."
  },
  {
    question: "Comment supprimer une commande?",
    answer: "Prévoir la réponse ici pour dire comment faire."
  },
  {
    question: "Comment contacter le support?",
    answer: "Vous pouvez envoyer un email à ......@kiwimetrics.io"
  }
];

const Help = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleHelp = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div style={styles.helpContainer}>
      <div style={styles.helpHeader}>
        <h1 style={styles.helpHeaderH1}>Help</h1>
      </div>
      {questionsAndAnswers.map((item, index) => (
        <div key={index} style={styles.helpItem}>
          <div
            style={{ ...styles.helpQuestion, ...(activeIndex === index ? styles.helpQuestionHover : {}) }}
            onClick={() => toggleHelp(index)}
          >
            {item.question}
            <span style={activeIndex === index ? styles.helpQuestionActive : {}}>{activeIndex === index ? '−' : '+'}</span>
          </div>
          <div style={{ ...styles.helpAnswer, ...(activeIndex === index ? styles.helpAnswerActive : {}) }}>
            <p style={{ fontSize: '1rem', fontWeight: 'normal' }}>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

Help.getLayout = (page) => <Layout text="help">{page}</Layout>;

export default Help;
