
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonApi } from './commonApi';
import { BASE_URL, requestConfig } from "../../util";

export const orderApi = commonApi.injectEndpoints({
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        ...requestConfig, // Include the requestConfig in the baseQuery
    }),
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: ({ supplier, limit, page }) => `orders/${supplier}?limit=${limit || 10}&page=${page || 1}`,
            providesTags: ['Orders'],
        }),
        placeOrder: builder.mutation({
            query: (data) => ({
                url: `orders`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Orders'],
        }),
        updateOrder: builder.mutation({
            query: (data) => ({
                url: `orders/${data.id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Orders'],
        }),
        deleteOrder: builder.mutation({
            query: (id) => ({
                url: `orders/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Orders'],
        }),

        deleteBill: builder.mutation({
            query: (id) => ({
                url: `orders/bill/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Orders'],
        }),
        getOrderDetails: builder.query({
            query: (id) => `orders/admin/details/${id}`,
            providesTags: ['Orders'],
        }),
    }),
});

export const {
    useGetOrdersQuery,
    usePlaceOrderMutation,
    useUpdateOrderMutation,
    useDeleteOrderMutation,
    useDeleteBillMutation,
    useGetOrderDetailsQuery,
} = orderApi;