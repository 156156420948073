import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../Layouts/Layout";
import ScreenLayout from "../../Layouts/ScreenLayout";
import './Orders.css';
import {
    amount,
    bill,
    date,
    deleteConfirmationMessage,
    deletedSuccessfully,
    deleteText,
    loading,
    order,
    paid,
    products,
    save,
    supplier,
    orderId as orderIdText,
    unitPrice,
    quantity
} from "../../Constants/translations";
import { useDeleteBillMutation, useDeleteOrderMutation, useGetOrderDetailsQuery, useUpdateOrderMutation } from "../../redux/apiServices/orderApi";
import { useGetSuppliersQuery } from "../../redux/apiServices/suppliersApi";
import moment from "moment";
import { BASE_URL as SERVER_URL } from "../../util";

const OrderDetails = () => {
    const { id: orderId } = useParams();  // Fetch orderId from URL
    const navigate = useNavigate();
    const language = localStorage.getItem('language');
    const merchantId = localStorage.getItem('merchant');

    const [errorText, setErrorText] = useState('');
    const [deleteOrderConfirmation, setDeleteOrderConfirmation] = useState(false);
    const [deleteBillConfirmation, setDeleteBillConfirmation] = useState(false);

    // Fetch the order details
    const { data: orderDetails, isLoading } = useGetOrderDetailsQuery(orderId);

    const {
        data: supplierData,
        isLoading: supplierLoading
    } = useGetSuppliersQuery({ merchant: merchantId, limit: 10, page: 1 }, {
        skip: !merchantId || merchantId === null
    });

    const [orderData, setOrderData] = useState({
        id: '',
        orderId: '',
        isBillPaid: false,
        isBillAttached: false,
        totalAmount: 0,
        billAmount: 0,
        items: [],
        supplierId: '',
        createdAt: '',
        billUrl: ''
    });

    const [updateOrder] = useUpdateOrderMutation();
    const [deleteBill] = useDeleteBillMutation();
    const [deleteOrder, { error: deleteOrderError, isLoading: isDeleting, isSuccess }] = useDeleteOrderMutation();

    useEffect(() => {
        if (!isLoading && orderDetails?.success) {
            setOrderData(orderDetails?.order);
        }
    }, [isLoading, orderDetails]);

    useEffect(() => {
        if (!isDeleting && deleteOrderError) {
            setErrorText("commande ne peut pas être supprimée");
        }
        else if (!isDeleting && isSuccess) {
            alert(order[language] + " " + deletedSuccessfully[language]);
            navigate('/orders');
        }
    }, [isDeleting, deleteOrderError, isSuccess, language, navigate]);

    const handleOrderDataChange = (e) => {
        const { name, value, type, checked } = e.target;
        setOrderData({
            ...orderData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleUpdateOrder = () => {
        updateOrder(orderData).then(() => {
            navigate('/orders');
        });
    };

    const handleDeleteOrder = () => {
        deleteOrder(orderData.id);
    };

    const handleDeleteBill = (orderId) => {
        deleteBill(orderId);
        setDeleteBillConfirmation(false);
    };

    if (isLoading || supplierLoading) {
        return <ScreenLayout>
            <div className="skeleton">

            </div>
        </ScreenLayout>
    }

    return (
        <div>
            <div className="order-details-container">
                <div className="order-details-header">
                    <h3 className="order-details-title">
                        {order[language]}: {orderData?.orderId}
                    </h3>
                    <button onClick={() => navigate('/orders')} className="icon-button">
                        <i className="fa-solid fa-x fa-2x" />
                    </button>
                </div>
                <div className="orders-input-div">
                    <span className="supplier-field">
                        {orderIdText[language]}: {orderData?.orderId}
                    </span>
                    <span className="supplier-field">
                        {date[language]}: {moment(orderData.createdAt).format('DD/MM/YYYY HH:mm')}
                    </span>
                    <span className="supplier-field">
                        {supplier[language]}: {
                            supplierData?.suppliers?.find(s => s.id === orderData.supplierId)?.name
                        }
                    </span>

                    {/* Order Items Table */}
                    <div className="order-items-container">
                        <table className="order-items-table">
                            <thead>
                                <tr>
                                    <th>{products[language]}</th>
                                    <th>{quantity[language]}</th>
                                    <th>{unitPrice[language]} (€)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData?.orderItems?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.product?.name}</td>
                                        <td>{item?.quantity}</td>
                                        <td>{item?.product?.unitPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div className="supplier-field">
                        {paid[language]}:
                        <input
                            type="checkbox"
                            checked={orderData?.isBillPaid}
                            name="isBillPaid"
                            onChange={handleOrderDataChange}
                        />
                    </div>
                    <div className="order-bill-field">
                        <span className="supplier-field">
                            {bill[language]}:
                        </span>
                        {orderData?.billAttached && (
                            <div className="order-bill-action">
                                <p className="bill-file">'Facture Attachée'</p>
                                <button
                                    className="icon-button icon-button-small"
                                    onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = `${SERVER_URL}${orderData?.billUrl}`;
                                        link.download = orderData?.id;
                                        link.click();
                                    }}
                                >
                                    <i className="fa-solid fa-download"></i>
                                </button>
                                <button
                                    className="icon-button fa-lg"
                                    onClick={() => setDeleteBillConfirmation(true)}
                                >
                                    <i className="fa-solid fa-trash bill-delete"></i>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="order-amount">
                        <span className="supplier-field">
                            {amount[language]}:
                        </span>
                        <input
                            type="text"
                            className="supplier-input"
                            value={orderData?.billAmount}
                            name="billAmount"
                            onChange={handleOrderDataChange}
                        />
                        <span>€</span>
                    </div>
                </div>

                <div className="order-action-buttons">
                    <button
                        className="supplier-save-button order-delete-button"
                        disabled={isDeleting}
                        onClick={() => setDeleteOrderConfirmation(true)}
                    >
                        <div />
                        <div>{isDeleting ? loading[language] : deleteText[language]}</div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                    <button className="supplier-save-button" onClick={handleUpdateOrder}>
                        <div />
                        <div>{save[language]}</div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>

                {errorText && <div className="error-text">{errorText}</div>}
            </div>

            {deleteOrderConfirmation && (
                <div className="delete-confirmation">
                    <span className="supplier-field">
                        {deleteConfirmationMessage[language]}
                    </span>
                    <button onClick={handleDeleteOrder} className="yes">
                        {isDeleting ? loading[language] : "Oui"}
                    </button>
                    <button onClick={() => setDeleteOrderConfirmation(false)} className="no">
                        Non
                    </button>
                </div>
            )}

            {deleteBillConfirmation && (
                <div className="delete-confirmation">
                    <span className="supplier-field">
                        {deleteConfirmationMessage[language]}
                    </span>
                    <button onClick={() => handleDeleteBill(orderData.id)} className="yes">
                        {isDeleting ? loading[language] : "Oui"}
                    </button>
                    <button onClick={() => setDeleteBillConfirmation(false)} className="no">
                        Non
                    </button>
                </div>
            )}
        </div>
    );
};

OrderDetails.getLayout = (page) => <Layout text={"Détails de la commande"}>{page}</Layout>;

export default OrderDetails;
