import { Fragment, useEffect, useMemo, useState } from "react";
import './Orders.css'
import Layout from "../../Layouts/Layout";
import {
    amount,
    bill,
    billAmountLang,
    bills,
    date,
    download,
    emailText,
    end,
    filters,
    loading,
    order,
    orderAmountLang,
    orderId,
    paid,
    products,
    save,
    selectSupplier as selectSupplierText,
    start,
    supplier
} from "../../Constants/translations";
import { useGetSuppliersQuery } from "../../redux/apiServices/suppliersApi";
import moment from "moment";
import { BASE_URL as SERVER_URL } from "../../util";
import { useGetMerchantOrdersQuery } from "../../redux/apiServices/merchantApi";
import ScreenLayout from "../../Layouts/ScreenLayout";
import { useNavigate } from "react-router-dom";

const Orders = () => {
    const navigate = useNavigate();
    let merchantId = localStorage.getItem('merchant');
    const {
        data: supplierData,
        isLoading: supplierLoading
    } = useGetSuppliersQuery({ merchant: merchantId, limit: 10, page: 1 }, {
        skip: !merchantId || merchantId === null
    });

    const [selectedSupplier, setSelectedSupplier] = useState('select');
    const { data: merchantOrders, isLoading: merchantProductsLoading } = useGetMerchantOrdersQuery(merchantId, {
        skip: !merchantId || merchantId === null
    })

    const [sendBills, setSendBills] = useState(false);
    const [filterBillAttached, setFilterBillAttached] = useState(false);
    const [filterIsBillPaid, setFilterIsBillPaid] = useState(false);
    const language = localStorage.getItem('language');
    const [pickingDates, setPickingDates] = useState(false);
    // keep start date from the start of time
    const [startDate, setStartDate] = useState(null);
    // keep end date to the end of time
    const [endDate, setEndDate] = useState(null);

    const filteredOrders = useMemo(() =>
        merchantOrders?.orders?.filter(order => {
            if (filterBillAttached && !order.billAttached) {
                return false;
            }

            if (filterIsBillPaid && !order.isBillPaid) {
                return false;
            }

            // check if there is some filter of supploier otherwise return all products
            if (selectedSupplier !== "select" && order.supplierId !== selectedSupplier) {
                return false;
            }

            if (startDate && endDate) {
                console.log('start date', startDate);
                console.log('end date', endDate);

                if (moment(order.createdAt).isBefore(startDate) || moment(order.createdAt).isAfter(endDate)) {
                    return false;
                }
            }
            return true;
        }), [merchantOrders, filterBillAttached, filterIsBillPaid, selectedSupplier, startDate, endDate]);

    const handleSendBills = async (method) => {
        if (method === "email") {
            let receiverEmail = ""; // Define the receiver email here
            const attachments = filteredOrders?.filter(order => order.billAttached).map(order => {
                return {
                    orderId: order.orderId,
                    orderDate: moment(order.createdAt).format('DD/MM/YYYY'),
                    supplier: supplierData?.suppliers?.find(supplier => supplier.id === order.supplierId)?.name,
                    billUrl: `${SERVER_URL}${order?.billUrl}`,
                    billPaid: order.isBillPaid ? "Oui" : "Non"
                };
            });

            if (attachments.length === 0) {
                alert("No bills attached to send");
                return;
            }

            // Constructing plain text email body
            const emailBody = attachments.map(attachment =>
                `Commande: ${attachment.orderId}\nDate de Commande: ${attachment.orderDate}\nFournisseur: ${attachment.supplier}\nLien de la facture: ${attachment.billUrl}\nFacture payée?: ${attachment.billPaid}
                
            `).join('\n');

            const emailLink = document.createElement('a');
            emailLink.href = `mailto:${receiverEmail}?subject=Factures de Commandes&body=${encodeURIComponent(emailBody)}`;
            emailLink.target = "_blank";
            emailLink.style.display = "none";
            document.body.appendChild(emailLink);

            emailLink.click();
            document.body.removeChild(emailLink);
        } else if (method === "download") {
            const attachments = filteredOrders?.filter(order => order.billAttached)?.map(order => {
                return {
                    name: order.id,
                    url: `${SERVER_URL}${order?.billUrl}`
                };
            });

            if (attachments.length === 0) {
                alert("No bills attached to download");
                return;
            }

            attachments.forEach(attachment => {
                fetch(attachment.url)
                    .then(response => response.blob())
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.download = attachment.name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(err => console.error('Error downloading the file: ', err));
            });
        }
    };

    const handleSupplierChange = (e) => {
        // if (e.target.value === 'select') return;
        setSelectedSupplier(e.target.value);
    };

    return (
        <div>
            <div className="orders-top">
                <button className="order-filters">
                    <i className="fa-solid fa-filter"></i>
                    <p>{filters[language]}</p>
                </button>
                <div className="orders-top-search">
                    <i className="fa-solid fa-calendar-days" onClick={
                        () => setPickingDates(true)
                    }></i>
                    <select className="order-search" onChange={handleSupplierChange}>
                        <option value={'select'}>
                            {selectSupplierText[language]}
                        </option>
                        {
                            !supplierLoading && supplierData?.suppliers?.map((supplier, index) => (
                                <option key={index} value={supplier.id}>
                                    {supplier.name}
                                </option>
                            ))
                        }
                    </select>
                    <div className="filter-check">

                        <label htmlFor="filterBillAttached" className="filter-label">{bill[language]}</label>
                        <input type="checkbox" id="filterBillAttached"
                            onChange={e => setFilterBillAttached(e.target.checked)} />
                    </div>
                    <div className="filter-check">

                        <label htmlFor="filterIsBillPaid" className="filter-label">{paid[language]}</label>
                        <input type="checkbox" id="filterIsBillPaid"
                            onChange={e => setFilterIsBillPaid(e.target.checked)} />
                    </div>

                </div>
            </div>
            {
                pickingDates && (
                    <div style={{ margin: '20px', backgroundColor: '#f5f5f5', border: '2px solid black', borderRadius: '5px' }}>
                        <div style={{ fontSize: '20px', fontWeight: 'bold', margin: '0 10px' }}>
                            {language === "0" ? "Pick Dates" : "Choisir des dates"}
                            <button onClick={() => setPickingDates(false)}
                                style={{ padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', margin: '5px' }}>
                                {language === "0" ? "Close" : "Fermer"}
                            </button>
                        </div>
                        <div style={{
                            marginTop: '10px',
                            backgroundColor: 'white',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'
                        }}>
                            {start[language]}: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            {end[language]}: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>
                    </div>
                )
            }
            <ScreenLayout>
                <div className="orders-list">
                    <table className="orders-table">
                        <tr>
                            <th>{orderId[language]}</th>
                            <th>{date[language]}</th>
                            <th>{supplier[language]}</th>
                            <th>{orderAmountLang[language]}</th>
                            <th>{bill[language]}</th>
                            <th>{paid[language]}</th>
                            <th>{billAmountLang[language]}</th>
                        </tr>
                        <tbody>
                            {
                                (!merchantProductsLoading) ? filteredOrders?.map((order, index) => (
                                    <tr key={index} className="order-list-item">
                                        <td
                                            onClick={
                                                () => {
                                                    // setEditOrder(order);
                                                    // setEditOrderData(order);
                                                    navigate(`/orders/${order.id}`);
                                                }
                                            }
                                        >{order?.orderId}</td>
                                        <td>
                                            {moment(order.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </td>
                                        <td>
                                            {
                                                supplierData?.suppliers?.map((supplier, index) => (
                                                    <Fragment key={index}>
                                                        {
                                                            supplier.id === order.supplierId && supplier?.name
                                                        }
                                                    </Fragment>
                                                ))
                                            }
                                        </td>
                                        <td>{order?.totalAmount}€</td>
                                        <td>
                                            <input type="checkbox" checked={
                                                order?.billAttached
                                            } disabled />
                                        </td>
                                        <td>
                                            <input type="checkbox" checked={
                                                order?.isBillPaid
                                            } disabled />
                                        </td>
                                        <td>{order?.billAmount ?? ''} €</td>
                                    </tr>
                                )) :
                                    <>
                                        <tr>
                                            {Array(6).fill(0).map((_, index) => (
                                                <td key={index}>
                                                    <div className="skeleton-container">
                                                        <div className="skeleton-cell">
                                                            ...
                                                        </div>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array(6).fill(0).map((_, index) => (
                                                <td key={index}>
                                                    <div className="skeleton-container">
                                                        <div className="skeleton-cell">
                                                            ...
                                                        </div>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array(6).fill(0).map((_, index) => (
                                                <td key={index}>
                                                    <div className="skeleton-container">
                                                        <div className="skeleton-cell">
                                                            ...
                                                        </div>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </table>
                </div>
            </ScreenLayout>
            <div className="place-order-button-div">
                <button className="place-order-button" onClick={
                    () => setSendBills(true)
                }>
                    <div />
                    {language === "0" ? "Send Bills" : "Envoyer des factures"}
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>

            {
                sendBills && (
                    <div className="add-supplier-form">
                        <div className="add-supplier-top">

                            <h3 className="add-supplier-heading">
                                {language === "0" ? "Send Bills" : "Envoyer des factures"}
                            </h3>
                            <button onClick={
                                () => setSendBills(false)
                            } className="icon-button">
                                <i className="fa-solid fa-x fa-2x" />
                            </button>
                        </div>
                        <div className="choose-div">
                            <div className="choose-ind">
                                <button className="choose-button mail" onClick={
                                    () => handleSendBills("email")
                                }>
                                    <i className="fa-solid fa-envelope"></i>
                                </button>
                                {emailText[language]}
                            </div>
                            <div className="choose-ind">
                                <button className="choose-button download" onClick={
                                    () => handleSendBills("download")
                                }>
                                    <i className="fa-solid fa-download fa-2x"></i>
                                </button>
                                {download[language]}
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}
Orders.getLayout = (page) => <Layout text={"Commande"}>{page}</Layout>

export default Orders;
